import { render, staticRenderFns } from "./MailUpdate.vue?vue&type=template&id=536c438b&scoped=true"
import script from "./MailUpdate.vue?vue&type=script&lang=js"
export * from "./MailUpdate.vue?vue&type=script&lang=js"
import style0 from "./MailUpdate.vue?vue&type=style&index=0&id=536c438b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536c438b",
  null
  
)

export default component.exports